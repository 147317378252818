import CryptoJS from "crypto-js";
import { format } from "date-fns";
import { jwtDecode } from "jwt-decode";
import crypto from "./crypto-browserify/index.js";
const { Buffer } = require("buffer");
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import useSocketContext from "./utilities/useSocketContext.jsx";
import { resetUserSettings } from "./store/features/settings/getUserSettingsSlice.jsx";
import { addUser } from "./store/features/user/userSlice.jsx";
import { useDispatch, useSelector } from "react-redux";

// Use a 128-bit key (16 bytes)
const secretKey = "619bf494133645e2";

// Use a 128-bit IV (16 bytes)
const iv = "c5d7d8f5d59fd3e4";

// encryption code
export const encrypt = (plaintext) => {
  const encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
  }).ciphertext.toString(CryptoJS.enc.Hex);

  return encrypted;
};

// decryption Code
export const decrypt = (encryptedHexString) => {
  const encryptedCiphertext = CryptoJS.enc.Hex.parse(encryptedHexString);

  const decryptedData = CryptoJS.AES.decrypt({ ciphertext: encryptedCiphertext }, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
  });

  const decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);
  return decryptedString;
};

//encryption AES_256_GCM
export function AES_256_GCM_Encrypt(data) {
  const text = JSON.stringify(data);
  // const key = crypto.randomBytes(32);
  const key = process.env.REACT_APP_SECRET_KEY;
  //console.log("key", key);
  const iv = crypto.randomBytes(12);
  const cipher = crypto.createCipheriv("aes-256-gcm", key, iv);

  let encrypted = cipher.update(text, "utf8", "hex");
  encrypted += cipher.final("hex");

  const authTag = cipher.getAuthTag().toString("hex");

  let second_encrypted = cipher.update(text, "utf8", "hex");
  second_encrypted += cipher.final("hex");

  return {
    iv: iv.toString("hex"),
    encryptedData: encrypted,
    authTag: authTag,
  };
}

//AES-256-GCM decryption
export async function AES_256_GCM_Decrypt(iv, authTag, encryptedData) {
  try {
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    if (!SECRET_KEY) {
      throw new Error("SECRET_KEY is not defined in the environment variables");
    }
    const ivBuffer = Buffer.from(iv, "hex");
    const authTagBuffer = Buffer.from(authTag, "hex");
    const encryptedBuffer = Buffer.from(encryptedData, "hex");

    const decipher = crypto.createDecipheriv("aes-256-gcm", SECRET_KEY, ivBuffer);
    decipher.setAuthTag(authTagBuffer);

    // Decrypt the data
    let decrypted = decipher.update(encryptedBuffer).toString("utf8");
    decrypted += decipher.final("utf8");

    const decryptData = JSON.parse(decrypted);

    //console.log("decryptData", decryptData)

    return decryptData;
  } catch (error) {
    throw new Error("Error decrypting data: " + error.message);
  }
}

// email format checker
export const emailFormatValidator = (email) => {
  // eslint-disable-next-line
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(email) === true) {
    return true;
  } else {
    return false;
  }
};

// get geo location function
export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          reject(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      reject(new Error("Geolocation is not supported"));
    }
  });
};

//get login token info
export const getLoginUserInfo = async (token) => {
  const gcm = jwtDecode(token);
  //decrypt gcm
  const decoded = await AES_256_GCM_Decrypt(gcm.encrypted.iv, gcm.encrypted.authTag, gcm.encrypted.encryptedData);
  return decoded;
};

// get token from session
export async function getTokenFromSession() {
  return sessionStorage.getItem("user_token");
}

// ********************************************** //
export const tabVisibility = (function () {
  var stateKey,
    eventKey,
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange",
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function (c) {
    if (c) document.addEventListener(eventKey, c);
    return !document[stateKey];
  };
})();

export const printConsole = (text) => {
  if (window._env_.REACT_APP_ENV === "test" && window._env_.REACT_APP_APP_MODE === "ONLINE") {
    // console.log(text);
  } else if (window._env_.REACT_APP_ENV === "localhost") {
    console.log(text);
  } else if (window._env_.REACT_APP_ENV === "qa") {
    console.log(text);
  }
};

export const printDevConsole = (text) => {
  if (window._env_.REACT_APP_ENV === "test" && window._env_.REACT_APP_APP_MODE === "ONLINE") console.log(text);
};

export const showOriginalFileName = ({ fileName }) => {
  try {
    let originalFileName = "";
    if (fileName) {
      let splitedFileName = fileName.split(/_(.+)/); //TP-1058
      // splitedFileName.shift();originalFileName = splitedFileName.join('-');
      originalFileName = splitedFileName[1];
    }
    return originalFileName;
  } catch (error) {
    printConsole("showOriginalFileName error", error);
    return "";
  }
};

export const isFileAllowed = ({
  localAssets,
  allowed_file_type = ["application/pdf", "image/png", "image/jpeg", "video/mp4"],
}) => {
  let flag = false;
  for (const file of localAssets) {
    if (allowed_file_type.includes(file.type)) {
      flag = true;
    }
  }
  return flag;
};

export const getWeekdayCountBetweenDates = (startDate, endDate, days_of_week) => {
  let totalDays = 0,
    i = new Date(startDate),
    modifiedEndDate = new Date(endDate);
  modifiedEndDate.setHours(23, 59, 59, 999); //set the end of the day
  for (i; i <= modifiedEndDate; i.setDate(i.getDate() + 1)) {
    if (days_of_week.includes(i.getDay())) {
      totalDays++;
    }
  }
  return totalDays;
};

export const getMonthCountBetweenDates = (startDate, endDate) => {
  let totalMonths = 0,
    modifiedEndDate = new Date(endDate),
    i = new Date(startDate);
  let dayOfMonth = i.getDate();
  modifiedEndDate.setHours(23, 59, 59, 999); //set the end of the day

  for (i; i <= modifiedEndDate; i.setDate(i.getDate() + 1)) {
    if (i.getDate() === dayOfMonth) {
      totalMonths++;
    }
  }
  return totalMonths;
};

//TP-4992
export const getHostAbbreviationForSocket = () => {
  printConsole(`the app url name is ${window._env_.REACT_APP_API_SOCKET_URL}`);
  try {
    const abbr =
      window._env_.REACT_APP_ENV === "local" ? "localhost" : window._env_.REACT_APP_API_SOCKET_URL.split(".")[0];
    return abbr;
  } catch (error) {
    printConsole(`error in getHostAbbreviationForSocket ${error}`);
    return "";
  }
};

export const checkDummyEmail = (row) => {
  try {
    const { email } = row;
    const splittedEmail = email.split("@");
    let isnum = /^\d+$/.test(splittedEmail[0]);
    // printConsole(`new user email has only phone number ${isnum}`)
    return isnum; //TP-6212
    // return (row.verified === "email" || row.verified === "both")
  } catch (error) {
    // printConsole(`error in checkDummyEmail ${error}`)
    return false;
  }
};

//extract number from string
export const formatMobile = (str) => {
  try {
    let newStr = str;
    if (str[0] == 0) {
      newStr = str.slice(1); //should not start with 0
    }
    return newStr.replace(/[^0-9]/g, "");
  } catch (error) {
    printConsole(`error in formatMobile ${error}`);
    return "";
  }
};

export const getProperRoles = (generic_name, defaultArr) => {
  try {
    let resArr = {};
    let parsedJson = JSON.parse(generic_name);
    for (var key in defaultArr) {
      if (Object.prototype.hasOwnProperty.call(defaultArr, key)) {
        if (parsedJson[key] === "" || parsedJson[key] === null) {
          let tmp = defaultArr[key];
          resArr[key] = tmp[0].toUpperCase() + tmp.slice(1);
        } else resArr[key] = parsedJson[key];
      }
    }
    return resArr;
  } catch (error) {
    printConsole(`error in getProperRoles ${error}`);
    return defaultArr;
  }
};

// export const replaceGenericName = (generic_name, key, defaultValue) => {
//     try{
//         const defaultObj = {
//             "admin": "Admin",
//             "expert": "Expert",
//             "technician": "Technician"
//         };
//         const customNames = getProperRoles(generic_name, defaultObj);
//         const value = customNames[key]
//         if(value){
//             return value
//         }else{
//             return defaultValue
//         }
//     }catch(error){
//         console.error(`error in replaceGenericName ${error}`)
//         return defaultValue
//     }
// }

/* ********************************* */

//YYYY-MM-dd Date Format
export const getDateYearMonthDay = (date) => {
  const formatDate = format(date, "yyyy-MM-dd");
  return formatDate;
};

//pass the custom_columns and existing coloumns takes array
export const createCustomColoumns = (columns, data) => {
  columns.forEach((column) => {
    const exists = data.columnData.some((col) => col.headerName === column.headerName);
    if (!exists) {
      data.columnData.push(column);
    }
  });
};

//get UUID
export const getUUID = (input) => {
  const parts = input.split("-");
  const uuid = parts.slice(1).join("-");
  return uuid;
};

//upload files on aws s3 bucket
export const uploadFileAWS = async (files) => {
  const MAX_NUM_FILE = process.env.REACT_APP_MAX_NUMBER_OF_UPLOADS;
  const MAX_UPLOAD_SIZE = process.env.REACT_APP_MAX_UPLOAD_SIZE;
  const MAX_FILENAME_LENGTH = 300;
  const filesArray = Array.from(files);
  let uploadPromises = [];
  //check the upload files limit
  if (filesArray.length > MAX_NUM_FILE)
    throw new Error("Upload failed: A maximum of 10 files can be uploaded. Please remove excess files and try again.");
  // AWS S3 configuration
  const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;
  const AWS_REGION = process.env.REACT_APP_AWS_S3_REGION;
  const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
  const SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    region: AWS_REGION,
  });
  const s3 = new AWS.S3();
  // Upload files to S3 and return in the desired format
  uploadPromises = filesArray.map((file) => {
    let fileName = file.name.split(" ").join("_").toLowerCase();
    let contentType = "";
    //file size validation
    if (file.size > MAX_UPLOAD_SIZE)
      throw new Error("Upload failed: File size exceeds the 50MB limit. Please select a smaller file and try again.");
    //file name length validation
    if (fileName.length > MAX_FILENAME_LENGTH)
      throw new Error(
        "Upload failed: File name exceeds the 300-character limit. Please rename the file and try again."
      );
    if (
      fileName.endsWith(".docx") ||
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".xlsx") ||
      fileName.endsWith(".csv")
    ) {
      contentType = "docs";
    } else if (fileName.endsWith(".wav") || fileName.endsWith(".mp3")) {
      contentType = "audio";
    } else if (fileName.endsWith(".mov") || fileName.endsWith(".mp4") || fileName.endsWith(".wav")) {
      contentType = "video";
    } else if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
      contentType = "image";
    } else {
      console.error("Unsupported file type:", fileName);
      return Promise.resolve(null); // Skip unsupported files
    }
    const file_url = `workflow/${window._env_.REACT_APP_ENV}/${contentType}/${fileName}`;
    const params = {
      Bucket: BUCKET_NAME,
      Key: file_url,
      Body: file,
      ContentType: file.type,
      CacheControl: "no-cache", //added to prevent file cache
      useAccelerateEndpoint: true, //for accelerating upload
    };
    // Return a Promise that resolves when the upload is complete
    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("There was an error uploading your file: ", err);
          reject(err);
        } else {
          console.log("Successfully uploaded file.", data);
          // Construct the reference format
          const reference = {
            name: fileName,
            location: data.Location,
            type: contentType,
            key: data.Key,
          };
          resolve(reference);
        }
      });
    });
  });
  // Wait for all uploads to complete
  try {
    const uploadedFiles = await Promise.all(uploadPromises.filter((p) => p));
    return uploadedFiles;
  } catch (error) {
    console.error("Error during file upload:", error);
    throw error;
  }
};

export const deleteFileAWS = async (fileKey) => {
  // AWS S3 configuration
  const BUCKET_NAME = window._env_.REACT_APP_AWS_BUCKET_NAME;
  const AWS_REGION = window._env_.REACT_APP_AWS_S3_REGION;
  const ACCESS_KEY = window._env_.REACT_APP_AWS_ACCESS_KEY;
  const SECRET_KEY = window._env_.REACT_APP_AWS_SECRET_KEY;

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    region: AWS_REGION,
  });

  const s3 = new AWS.S3();

  const params = {
    Bucket: BUCKET_NAME,
    Key: fileKey, // The unique key for the file in the S3 bucket
  };

  // Return a promise for deleting the file
  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error("Error deleting file from S3:", err);
        reject(err);
      } else {
        console.log("Successfully deleted file from S3", data);
        resolve(data);
      }
    });
  });
};

// capitalize the 1st letter of the word
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// look up the KC Status format
export const lookUptheKCStatus = (value) => {
  let label = "";
  value === "draft" && (label = "Draft");
  (value === "in_progress" || value === "inprogress") && (label = "In Progress");
  value === "created" && (label = "Created");
  value === "published" && (label = "Published");
  value === "assigned" && (label = "Assigned");
  value === "finished" && (label = "Finished");

  return label;
};

export const lookUptheSourceType = (value) => {
  let label = "";
  value == "" && (label = "Template - new"); //new template
  value === "template" && (label = "Template - cloned"); //Cloned Template 
  value === "kc" && (label = "Template - kc");//Kc to template
  value === "job" && (label = "Job"); //Cloned Job
  return label;
};

//look up the Customer Status format
export const lookUptheCustomerStatus = (value) => {
  let label = "";
  value === "pending" && (label = "Pending");
  value === "active" && (label = "Active");
  value === "inactive" && (label = "Inactive");
  value === "rejected" && (label = "Rejected");
  return label;
};

export function checkUserTokerExpiry(response) {
  const dispatch = useDispatch();
  const { disconnectSocket } = useSocketContext();
  if (response?.success === false && response?.statusCode === 498) {
    // Show warning if message is available
    toast.warning(response.message);
    // Redirect to the login page or home page
    // Clear session data and disconnect socket
    sessionStorage.removeItem("user_token");
    sessionStorage.removeItem("pageName");
    sessionStorage.clear();
    //disconnect socket
    disconnectSocket();
    dispatch(addUser({ currentUser: {} }));
    dispatch(resetUserSettings());
    history.push("/");
    window.location.reload();
  }
}

export const customSort = (rows, selector, direction) => {
  let new_rows = rows.sort((rowA, rowB) => {
    // use the selector function to resolve your field names by passing the sort comparitors
    const aField = selector(rowA);
    const bField = selector(rowB);
    let finalaField, finalbField;
    //console.log(aField, bField);
    //console.log(/^\-?\d+((\.)\d+)?$/.test(aField), /^\-?\d+((\.)\d+)?$/.test(bField));
    //TZ-1440 -- If its a numbers only (positive, negative or decimel) field
    if (/^-?\d+((.)\d+)?$/.test(aField) === true && /^-?\d+((\.)\d+)?$/.test(bField) === true) {
      finalaField = parseInt(aField);
      finalbField = parseInt(bField);
    } //else if (moment(aField, "MMM DD, YYYY at HH:mm AM/PM").isValid() === true && moment(bField, "MMM DD, YYYY at HH:MM AM/PM").isValid() === true) {
    else if (!isNaN(Date.parse(aField)) === true && !isNaN(Date.parse(bField)) === true) {
      finalaField = new Date(aField);
      finalbField = new Date(bField);
    } else if (aField !== null && bField !== null) {
      //TZ-1022
      finalaField = aField.toLowerCase();
      finalbField = bField.toLowerCase();
    } else {
      //TZ-1022
      finalaField = aField;
      finalbField = bField;
    }
    let comparison = 0;
    if (finalaField > finalbField) {
      comparison = 1;
    } else if (finalaField < finalbField) {
      comparison = -1;
    }
    return direction === "desc" ? comparison * -1 : comparison;
  });
  const newArray2 = new_rows.map((item, index) => {
    return Object.assign({}, item, { slNo: index + 1 });
  });
  return newArray2;
};
