import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { current } from '@reduxjs/toolkit'

const initialState = {
    Categories:[],
    isLoading:false,
    error:null,
    success:false,
    isCategoryLoading:false,
    selectedCategory: undefined,
    selectedAction: '',
    isEditSubmitted: false,
    isEditCategory:false
};

export const fetchAllCategoryList = createAsyncThunk(
    'categories/get-all-categories',
    async (_,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.get(admin_api.getAllCategories.url,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        //console.log('res1',response);
        return response.data;
        
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

const CategorySlice = createSlice({
    name: 'Categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(fetchAllCategoryList.pending, (state) => {
        state.isCategoryLoading = true;
      })
      .addCase(fetchAllCategoryList.fulfilled, (state, action) => {
        state.Categories = action.payload;
        state.isCategoryLoading = false;
      })   
      .addCase(fetchAllCategoryList.rejected, (state, action) => {
        state.isCategoryLoading = false;
        state.errorMessage = action.payload;
      })
    }
})

export default CategorySlice.reducer;