import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { current } from '@reduxjs/toolkit'

const initialState = {
    locations:[],
    isLoading:false,
    error:null,
    success:false,
    isLocationLoading:false,
    selectedLocation: undefined,
    selectedAction: '',
    isEditSubmitted: false,
    isEditLocation:false
};

export const fetchAllLocationList = createAsyncThunk(
    'locations/get-all- locations',
    async (_,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.get(admin_api.getAllLocations.url,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        return response.data;
        
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

const CategorySlice = createSlice({
    name: 'Locations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(fetchAllLocationList.pending, (state) => {
        state.isLocationLoading = true;
      })
      .addCase(fetchAllLocationList.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.isLocationLoading = false;
      })   
      .addCase(fetchAllLocationList.rejected, (state, action) => {
        state.isLocationLoading = false;
        state.errorMessage = action.payload;
      })
    }
})

export default CategorySlice.reducer;