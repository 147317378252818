import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { current } from '@reduxjs/toolkit';

const initialState = {
  incidents:[],
  finished_incidents:[],
  open_incidents:[],
  isLoading:false,
  error:null,
  success:false,
  isIncidentLoading:false,
  selectedIncident: undefined,
  selectedAction: '',
  isEditSubmitted: false,
  isEditIncident:false,
  isOpenIncident:true,
};

export const addIncident = createAsyncThunk(
  'incidents/add-incident',
  async (incidents,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(admin_api.addIncident.url, incidents,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllIncidentList = createAsyncThunk(
  'incidents/get-all-incidents',
  async (daterange,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(admin_api.getAllincidents.url,daterange,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editIncidentList = createAsyncThunk(
  'incidents/edit-incident',
  async (incidents,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.put(admin_api.updateIncident.url,incidents,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addIncidentSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    updateSelectedIncident(state, action){
      state.selectedIncident = action.payload.id;
      state.selectedAction = action.payload.action;
    },
    updateEditSubmitted(state, action){
      state.isEditSubmitted = action.payload;
    },
    updateIncidentlist(state,action){
      state.isOpenIncident = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addIncident.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addIncident.fulfilled, (state, action) => {
        state.isLoading = false; 
        state.success = action.payload.success;
      })
      .addCase(addIncident.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = action.payload.success;
      })
      .addCase(fetchAllIncidentList.pending, (state) => {
        state.isIncidentLoading = true;
      })
      .addCase(fetchAllIncidentList.fulfilled, (state, action) => {
        state.incidents = action.payload;
        state.finished_incidents = action.payload?.incidents?.filter(inc=>inc.status==='finished');
        state.open_incidents = action.payload?.incidents?.filter(inc=>inc.status!=='finished')
        state.isIncidentLoading = false;
      })   
      .addCase(fetchAllIncidentList.rejected, (state, action) => {
        state.isIncidentLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(editIncidentList.pending, (state) => {
        state.isEditIncident = true;
      })
      .addCase(editIncidentList.fulfilled, (state, action) => { 
        if(action.payload.incidents !==undefined){       
          state.isEditSubmitted = true;
          state.isEditIncident = false; 
        }      
      })
      .addCase(editIncidentList.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isEditIncident = false;
      });
  },
});

export default addIncidentSlice.reducer;
export const { updateSelectedIncident, updateEditSubmitted, updateIncidentlist } = addIncidentSlice.actions