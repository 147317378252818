import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { current } from '@reduxjs/toolkit';

const initialState = {
    comments:[],
    isLoading:false,
    error:null,
    success:false,
    isCommentLoading:false,
    selectedComment: undefined,
    selectedAction: '',
    isEditSubmitted: false,
    isEditComment:false
  };

export const addComment = createAsyncThunk(
    'comments/add-comment',
    async (Comment,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.post(admin_api.addComment.url, Comment,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return response.data;
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchAllCommentsList = createAsyncThunk(
    'comments/get-all-comments',
    async (reference,{rejectWithValue} ) => {
      try{
        const token = await getTokenFromSession();
        const response = await axios.post(admin_api.getAllComments.url,reference,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return response.data;
        
      }catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

const addCommentSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addComment.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(addComment.fulfilled, (state, action) => {
                // let old_comment = [...(state.comments)];
                // let newpayload = Object.assign({},action.payload.comment);
                // let commentPayload = {
                //     id: "",
                //     c_id: "",
                //     ref_from: "tp_incidents",
                //     ref_id: "efe0a108-39f0-43cb-9009-d40cefdb4cdf",
                //     remarks: "Test remarks for other incident",
                //     created_by: "36c5173b-702e-44e4-a24d-f4e1e65c1e1d",
                //     deleted_by: null,
                //     is_deleted: false,
                //     createdAt: "2024-11-16T05:54:19.299Z",
                //     updatedAt: "2024-11-16T05:54:19.299Z",
                //     deletedAt: null
                // }
                // old_comment.push(newpayload);
                // state.comments = old_comment;
                state.success = action.payload.success;
            })
            .addCase(addComment.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                state.success = action.payload.success;
            })
            .addCase(fetchAllCommentsList.pending, (state) => {
                state.isCommentLoading = true;
            })
            .addCase(fetchAllCommentsList.fulfilled, (state, action) => {
                state.comments = action.payload;
                state.isCommentLoading = false;
            })   
            .addCase(fetchAllCommentsList.rejected, (state, action) => {
                state.isCommentLoading = false;
                state.errorMessage = action.payload;
            })
    }
})

export default addCommentSlice.reducer;